import { useEffect } from "react";

const useDynamicMetadata = () => {
  useEffect(() => {
    // Set Title
    document.title = process.env.REACT_APP_TITLE || "Abroadly";

    // Set Description
    const metaDesc = document.querySelector("meta[name='description']");
    if (metaDesc) {
      metaDesc.setAttribute(
        "content",
        process.env.REACT_APP_DESCRIPTION ||
          "Abroadly - Konsalting kompaniyasini avtomatlashtirish platformasi"
      );
    }

    // Set Favicon
    const favicon = document.querySelector("link[rel='icon']");
    if (favicon) {
      favicon.setAttribute(
        "href",
        process.env.REACT_APP_FAVICON || "/favicon-abroadly.ico"
      );
    }

    // Set Apple Touch Icon
    const appleTouchIcon = document.querySelector(
      "link[rel='apple-touch-icon']"
    );
    if (appleTouchIcon) {
      appleTouchIcon.setAttribute(
        "href",
        process.env.REACT_APP_APPLE_TOUCH_ICON ||
          "/apple-touch-icon-abroadly.png"
      );
    }

    // Set Manifest
    const manifest = document.querySelector("link[rel='manifest']");
    if (manifest) {
      manifest.setAttribute(
        "href",
        process.env.REACT_APP_MANIFEST || "/manifest-abroadly.json"
      );
    }
  }, []);
};

export default useDynamicMetadata;
