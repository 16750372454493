import {
  faDollarSign,
  faFileLines,
  faFolderOpen,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { message, TabsProps } from "antd";
import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import ApplicationInfo from "../components/applicationInfo/ApplicationInfo";
import useApplicationsContext from "routes/applications/list/services/applicationsContext";
import PersonalInfo from "../components/personalInfo/PersonalInfo";
import Documents from "../components/documents/container";
import api from "common/api";
import Payments from "../components/payments/Payments";
import { localTheme } from "assets/theme/theme";

const Context = () => {
  const {
    state: { taskInfo: task, notifications, notificationsLoading },
    actions: { handleTaskInfo },
  } = useApplicationsContext();

  const setEmployee = async (value: string) => {
    if (value) {
      if (task) {
        try {
          const response = await api.application.setEmployee({
            _id: task?._id,
            employee_id: value,
          });
          handleTaskInfo(task);
          // handleTaskInfo((prev) =>
          //   prev ? { ...prev, employee_id: response.data.employee_id } : prev
          // );
          // setTaskInfo(response.data);
        } catch (err) {
          console.log(err);
        }
      }
    } else {
      message.error("Arizadan xodimni olib tashlash mumkin emas!");
    }
  };

  const iconStyle: React.CSSProperties = {
    aspectRatio: 1 / 1,
    padding: "5px",
    backgroundColor: localTheme.primary,
    color: "white",
    borderRadius: 5,
    textAlign: "left",
  };

  const tabItems: TabsProps["items"] = [
    {
      icon: <FontAwesomeIcon icon={faFileLines} style={iconStyle} />,
      label: "Ariza ma'lumotlari",
      key: "applicationInfo",
      children: (
        <ApplicationInfo
          task={task}
          notifications={notifications}
          notificationsLoading={notificationsLoading}
        />
      ),
    },
    {
      icon: <FontAwesomeIcon icon={faUser} style={iconStyle} />,
      label: "Shaxsiy ma'lumotlari",
      key: "personalInfo",
      children: <PersonalInfo task={task} />,
    },
    {
      icon: <FontAwesomeIcon icon={faFolderOpen} style={iconStyle} />,
      label: "Kerakli hujjatlar",
      key: "documents",
      children: <Documents task={task} />,
    },
    {
      icon: <FontAwesomeIcon icon={faDollarSign} style={iconStyle} />,
      label: "To'lov ma'lumotlari",
      key: "payments",
      children: <Payments task={task} />,
    },
  ];

  return {
    state: { tabItems },
    actions: { setEmployee },
  };
};

const InfoModalContext = createContext<any>({ state: {}, actions: {} });

export const InfoModalContextProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const value = Context();
  return (
    <InfoModalContext.Provider value={value}>
      {children}
    </InfoModalContext.Provider>
  );
};

export default function useInfoModalContext() {
  return useContext<ReturnType<typeof Context>>(InfoModalContext);
}
